import React, { Component } from 'react';
import { BrowserRouter as Router , Route} from 'react-router-dom';
import Certificado from './components/Certificado/Certificado';


class App extends Component {
	render() {
		return (
			<Router>
					<div>
            <Route exact path="/MadridCamper"><Certificado nombreEmpresa="Madrid Camper" cif="B87734257" razonSocial="Madrid Camper Van S.L" sitioWeb="www.madridcamper.com" fechaAdhesion="Abril de 2021" tipoEnergia="Solar y eólica" procedencia="Parque solar Don Rodrigo (Sevilla)" coordenadas={"37°13'15.2\"N 5°52'04.6\"W"} procedencia2="Parque eólico Becerril (Palencia)" coordenadas2={"42° 7' 40.3\"N -4° 38' 6.7\"W"} procedenciaLink="https://www.google.com/maps/place/37%C2%B013'15.2%22N+5%C2%B052'04.6%22W/@37.2208932,-5.8701331,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d37.2208889!4d-5.8679444" procedenciaLink2="https://www.google.es/maps/place/42%C2%B007'40.3%22N+4%C2%B038'06.7%22W/@42.1380742,-4.6054781,12535m/data=!3m1!1e3!4m5!3m4!1s0x0:0x0!8m2!3d42.1278611!4d-4.6351944"/></Route>
			<Route exact path="/Berde"><Certificado nombreEmpresa="Berde" cif="B82410549" razonSocial="Gestiner Ingenieros S.L" sitioWeb="www.esberde.es" fechaAdhesion="Abril de 2021" tipoEnergia="Solar y eólica" procedencia="Parque solar Don Rodrigo (Sevilla)" coordenadas={"37°13'15.2\"N 5°52'04.6\"W"} procedencia2="Parque eólico Becerril (Palencia)" coordenadas2={"42° 7' 40.3\"N -4° 38' 6.7\"W"} procedenciaLink="https://www.google.com/maps/place/37%C2%B013'15.2%22N+5%C2%B052'04.6%22W/@37.2208932,-5.8701331,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d37.2208889!4d-5.8679444" procedenciaLink2="https://www.google.es/maps/place/42%C2%B007'40.3%22N+4%C2%B038'06.7%22W/@42.1380742,-4.6054781,12535m/data=!3m1!1e3!4m5!3m4!1s0x0:0x0!8m2!3d42.1278611!4d-4.6351944"/></Route>
		  </div>
			</Router>
		);
	}
}
export default App;
