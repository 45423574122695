import React from 'react';
// import PieChart from 'react-minimal-pie-chart';
import './Certificado.css';
// import logo from '../../assets/logos/LOGO_COMPLETO_Blanco.png'
import blueArrow from '../../images/icons/arrow-right-solid.svg';
class Certificado extends React.Component {

    render(){
        return( 
            <div className="certificadoComponent">
                <div className="firstHalf half"> 
                        <div className="logoDiv">
                            <div className="logo">

                            </div>
                        </div>
                        <div className="contentDiv"> 
                            <p className="title">Compromiso Berde</p>
                            <p>Es el sello digital de calidad energética más exigente de España.</p>
                            <p>Un distintivo que muestran aquellas empresas cuyo suministro eléctrico es de origen 100% renovable.</p>
                            {/* <p>Un distintivo que muestran aquellas
                                empresas cuyo suministro eléctrico es de
                                origen 100% renovable y <span>comprobable.</span></p> */}
                                <p>Para poder lucirlo, Berde Comercializadora debe constatar y certificar que la energía 
                                    consumida por la empresa está generada en España (no en Europa), 
                                    es de origen solar o eólica y se conoce el punto exacto, coordenadas incluidas, donde se produce.</p>
                            {/* <p>Para poder lucirlo, Berde Comercializadora
                                debe constatar y certificar que la energía
                                consumida por la empresa está generada
                                en España (no en Europa), es de origen
                                solar o eólica y que <span>se consume en el
                                mismo momento que se produce.</span></p> */}
                            <div className="flex1"></div>
                            {/* <p>Para más información sobre este sello, haz
                                clic <span>aquí.</span></p> */}
                        </div>
                </div>
                <div className="secondHalf half">
                    <div className="contentDiv"> 
                            <p className="title">{this.props.nombreEmpresa}</p>
                            <div className="itemGroup">
                                <img src={blueArrow}/>
                                <p className="bold">Razón Social</p>
                                <p>{this.props.razonSocial}</p>
                            </div>
                            <div className="itemGroup">
                                <img src={blueArrow}/>
                                <p className="bold">CIF</p>
                                <p>{this.props.cif}</p>
                            </div>
                            <div className="itemGroup">
                                <img src={blueArrow}/>
                                <p className="bold">Sitio web</p>
                                <a href={"https://"+this.props.sitioWeb}><p>{this.props.sitioWeb}</p></a>
                            </div>
                            <div className="itemGroup">
                                <img src={blueArrow}/>
                                <p className="bold">Fecha de adhesión</p>
                                <p>{this.props.fechaAdhesion}</p>
                            </div>
                            <div className="itemGroup">
                                <img src={blueArrow}/>
                                <p className="bold">Procedencia de la energía consumida</p>
                                <a href={this.props.procedenciaLink}><p>{this.props.procedencia} {this.props.coordenadas}</p></a>
                                {this.props.procedencia2 && <a href={this.props.procedenciaLink2}><p>{this.props.procedencia2} {this.props.coordenadas2}</p></a>}
                            </div>                         
                            <div className="itemGroup">
                                <img src={blueArrow}/>
                                <p className="bold">Tipo de energía</p>
                                <p>{this.props.tipoEnergia}</p>
                            </div>
                        </div>

                </div>
            </div>
        // <div class="container-fluid">
        //     <div class="divCertificado">
        //             <div class="row align-items-center row1">
        //             <div class="col-12 col-md-4 col11">
        //                 <img class="logoVerde center-block" src={logo} alt="LogoVerde"></img>
        //                 <p class="mt-5">Confianza Online es el sello de calidad en Internet líder en España. Un distintivo que muestran aquellas empresas que garantizan la máxima transparencia, seguridad y confianza a la hora de comprar y navegar en sus webs.</p>

        //                 <p>Para poder lucirlo, la empresa tiene que cumplir con los estándares incluidos en nuestro Código Ético</p>
        //             </div>
        //             <div class="col-12 col-md-8 col12 p-5">
        //                 <div>
        //                 <h1>{this.props.nombreEmpresa}</h1>
        //                 <p>Nombre comercial: {this.props.nombreEmpresa}</p>
        //                 <p>Sitio web: <a href={this.props.sitioWeb}>{this.props.sitioWeb}</a></p>
        //                 <p>Razón social: {this.props.razonSocial}</p>
        //                 <p>CIF: {this.props.cif}</p>
        //                 <p>Adherido desde: {this.props.fechaAdhesion}</p>
        //                 <p>Certificado expedido y garantizado por: <a href="https://www.cnmc.es/">CNMC – comisión nacional de losmercados y la competencia</a></p>
        //                 <p>La energía proviene de: {this.props.paisesProcedencia}</p>
        //                 <p>El tipo de energía consumida es: {this.props.tipoEnergia}</p>
        //                 {/* <div id="pieChartDiv">
        //                     <PieChart
        //                         data={[
        //                         { title: 'On', value: 10, color: '#E38627' },
        //                         { title: 'Two', value: 15, color: '#C13C37' },
        //                         { title: 'Three', value: 20, color: '#6A2135' },
        //                         ]}                    
        //                     />
        //                 </div>  */}
        //                 </div>
                         
        //             </div>
        //             </div>
                 
        //             <div class="row align-items-center row2">
        //                 <div class="col-12 col21">                 
        //                 <p class="mt-5">Confianza Online es el sello de calidad en Internet líder en España. Un distintivo que muestran aquellas empresas que garantizan la máxima transparencia, seguridad y confianza a la hora de comprar y navegar en sus webs.</p>
        //                 <p>Para poder lucirlo, la empresa tiene que cumplir con los estándares incluidos en nuestro Código Ético</p>
        //                 </div>    
        //             </div>
        //             </div>
        //             </div>

        )
    }
}

export default Certificado;